import React from "react";
import {Box} from '@mui/material';
import {LineChart, MarkPlot, lineElementClasses, markElementClasses} from "@mui/x-charts";

const CustomLegend = () => {
    const graphLegendData = [
        {
            label: "Costs",
            color: "#DF901F",
        },
        {
            label: "Revenue",
            color: "#1C5838",
        }
    ];

    return (
        <Box display="flex" justifyContent="center" alignItems="center" gap={1} paddingBottom={2}>
            {graphLegendData.map((item, index) => (
                <Box key={index} display="flex" alignItems="center">
                    <Box className="custom-graph-legend"
                         sx={{backgroundColor: item.color}}
                    />
                    <span style={{color: '#848484'}}>
                        {item.label}
                    </span>
                </Box>
            ))}
        </Box>
    )
};

const dataset = [
    {month: 'Jan', costs: 900000, revenue: 1202000},
    {month: 'Feb', costs: 4500000, revenue: 1800000},
    {month: 'Mar', costs: 6000000, revenue: 220000},
    {month: 'Apr', costs: 700000, revenue: 2500000},
    {month: 'May', costs: 800000, revenue: 1350000},
    {month: 'Jun', costs: 750000, revenue: 280000},
    {month: 'Jul', costs: 900000, revenue: 132000},
    {month: 'Aug', costs: 850000, revenue: 310000},
    {month: 'Sept', costs: 950000, revenue: 330000},
    {month: 'Oct', costs: 1000000, revenue: 340000},
    {month: 'Nov', costs: 1050000, revenue: 350000},
    {month: 'Dec', costs: 1100000, revenue: 3600000},
];

const valueFormatter = (value: number | null) => {
    if (value === null) return '';

    if (value >= 1_000_000) {
        return `${(value / 1_000_000).toFixed(1)}M`;
    } else if (value >= 1_000) {
        return `${(value / 1_000).toFixed(1)}K`;
    } else {
        return `${value}`;
    }
};

export const InventoryCostsVersusRevenueGraph = () => {
    return (
        <>
            <Box>
                <LineChart
                    dataset={dataset}
                    xAxis={[{scaleType: 'band', dataKey: 'month', disableTicks: true,}]}
                    series={[
                        {
                            dataKey: 'costs',
                            label: 'Costs',
                            color: '#DF901F',
                            valueFormatter: (value) => valueFormatter(value),
                            highlightScope: {
                                highlight: "item",
                            }
                        },
                        {
                            dataKey: 'revenue',
                            label: 'Revenue',
                            color: '#1C5838',
                            valueFormatter: (value) => valueFormatter(value),
                            highlightScope: {
                                highlight: "item",
                            }
                        },
                    ]}
                    yAxis={[{
                        disableTicks: true,
                        valueFormatter: (value) => valueFormatter(value),
                    }]}
                    height={350}
                    margin={{
                        right: 20,
                        left: 60
                    }}
                    grid={{horizontal: true}}
                    sx={{
                        '& .MuiChartsLegend-root': {
                            display: 'none',
                        },
                        '& .MuiChartsGrid-root line': {
                            stroke: '#EAEAEA',
                            strokeDasharray: '4 4',
                        },
                        '& .MuiChartsAxis-root .MuiChartsAxis-line': {
                            display: 'none',
                        },
                        '& .MuiChartsAxis-root .MuiChartsAxis-tickLabel': {
                            fill: '#A0A0A0',
                            fontsize: 14,
                            fontWeight: 400,
                        },
                        [`& .${lineElementClasses.root}`]: {
                            strokeWidth: 2,
                        },
                        [`& .${markElementClasses.root}`]: {
                            scale: '0.5',
                            fill: '#fff',
                            strokeWidth: 2,
                        },
                    }}
                />
                <CustomLegend/>
            </Box>
        </>
    )
}

import { IHeaderButtonProps, SettingsPageHeader } from "../../../components/shared/SettingsPageHeader";
import React, { useEffect, useState } from "react";
import TableWrapper from "../../../components/shared/TableWrapper";
import { ITableColumn, XTable } from "../../../components/shared/XTable";
import { ICostCentre, IInput } from "../interfaces/IHub";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { IApiResponse, IPaginationMetadata } from "../../../interfaces/IApiResponse";
import { IState } from "../../../../data/types";
import { SETTINGS_CONSTANTS } from "../../../../data/redux/settings/settingsReducer";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import { toast } from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../data/toastDefaults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import XPagination from "../../../components/shared/XPagination";
import CostCentreDetails from "./CostCentreDetails";
import CreateCostCentre from "./modals/CreateCostCentre";

interface IProps {
    hubId?: string;
    title?: string;
}

const columns: ITableColumn[] = [
    {
        label: 'Cost Center Name',
        id: 'name',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Description',
        id: 'description',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Units',
        id: 'units',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
]

export const HubCostCentersPage = ({ title, hubId }: IProps) => {

    const dispatch = useDispatch();

    const navigate = useNavigate()

    const { currentUser } = useAuth();
    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [selected, setSelected] = useState<any[]>([])
    const [filteredCostCentres, setFilteredCostCentres] = useState<ICostCentre[]>([]);

    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 5,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });

    const initialValues = {
        hubId: '',
        name: '',
        description: '',
        // units: []
    }

    const costCentres: ICostCentre[] = useSelector((state: IState) => state.settings.costCenters);


    const [importing, setImporting] = useState<boolean>(false);
    const [showActionButtons, setShowActionButtons] = useState<boolean>(true)
    const [selectedCostCentre, setSelectedCostCentre] = useState<ICostCentre>(initialValues)

    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            setSearchTerm(undefined)
        }

        if (event.keyCode === 13) {
            setSearchTerm(event.target.value)
        }
    }

    const setLoading = (state: boolean) => {
        dispatch({
            type: SETTINGS_CONSTANTS.LOADING_DATA,
            payload: state
        })
    }

    const filterCostCentres = () => {
        if (searchTerm) {
            setFilteredCostCentres(costCentres.filter(costCentre =>
                costCentre.name.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        } else {
            setFilteredCostCentres(costCentres);
        }
    }

    useEffect(() => {
        filterCostCentres()
    }, [searchTerm, costCentres]);

    useEffect(() => {
        setLoading(true);
        handleFetchData(page, pagination.pageSize);
    }, [page, dispatch]);

    const handleFetchData = (page: number, pageSize: number) => {
        if (remoteRoutes.onboardingService) {
            let params: any = { hubId, page, pageSize };

            const url = buildUrl(remoteRoutes.onboardingService, '/cost/centres', params);
            get(url,
                (response: IApiResponse) => {
                    const { data, paginationMetadata } = response;
                    setPagination(paginationMetadata);

                    dispatch({
                        type: SETTINGS_CONSTANTS.FETCH_COST_CENTRES,
                        payload: data
                    });

                    // toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
                    setLoading(false);
                });
        }
    }

    const handleSelectAll = (rows: any[]) => {
        setSelected(prevSelected => {
            if (prevSelected.length === rows.length) {
                return [];
            } else {
                return rows.map(row => row.id);
            }
        })
    };

    const handleCheckRow = (item: ICostCentre) => {

        setSelected(prevSelected => {
            const newSelected = prevSelected ? [...prevSelected] : []

            const index = newSelected.indexOf(item.id);
            if (index > -1) {
                // Remove the id if it's already in the array
                newSelected.splice(index, 1);
            } else {
                // Add the id if it's not in the array
                newSelected.push(item.id);
            }

            return newSelected
        })
    };

    const handleClickRow = (item: ICostCentre) => {
        setDrawerVisible(!drawerVisible);
        setSelectedCostCentre(item)
    };

    const actionButtons: IHeaderButtonProps[] = [{
        label: "Add a Cost Centre",
        cssClass: "btn btn-primary btn-sm",
        dataTarget: "#new-cost-centre",
        dataToggle: "modal"
    }];

    return (
        <>
            {title && <SettingsPageHeader title={title} />}
            <TableWrapper>
                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div className="input-group w-auto">
                        <input type="text" onKeyUp={(event) => handleSearch(event)}
                            className="form-control"
                            placeholder="Type to search..." />
                        <span className="input-group-text" id="addon-wrapping">
                            <FontAwesomeIcon icon={faSearch} />
                        </span>
                    </div>

                    <div className="action-buttons">

                        {/* <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                            <FontAwesomeIcon icon={faFilter} className="text-orange"/>
                            <span className="ms-2">Filters</span>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-secondary ms-2 me-2">
                            <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>
                        </button> */}

                        {showActionButtons &&
                            actionButtons.map((button, index) => {
                                return (<button
                                    data-bs-toggle={button.dataToggle}
                                    data-bs-target={button.dataTarget}
                                    className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                    key={index}
                                    onClick={button.onClick}>
                                    {button.processing ? 'Please wait...' : button.label}
                                </button>)
                            })
                        }

                    </div>
                </div>

                <XTable
                    selected={selected}
                    onSelectAll={(rows) => handleSelectAll(rows)}
                    onRowClick={(row) => handleClickRow(row)}
                    onCheckRow={(row) => handleCheckRow(row)}
                    data={filteredCostCentres}
                    columns={columns}
                    loading={false} />

                <XPagination
                    dataLength={costCentres.length}
                    pagination={pagination}
                    currentPage={page}
                    setPage={(page) => setPage(page)} />
            </TableWrapper>

            <CostCentreDetails close={() => setDrawerVisible(false)} show={drawerVisible}
                costCentre={selectedCostCentre} />

            <CreateCostCentre hubId={hubId} />
        </>
    );
}
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { Field, Formik, FieldArray } from "formik";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { get, post, put } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../../data/toastDefaults";
import { useAuth } from "../../../auth";
import { useDispatch, useSelector } from "react-redux";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import { IHub, IInputCategory, IUnit } from "../../interfaces/IHub";
import { IState } from "../../../../../data/types";
import { IApiResponse } from "../../../../interfaces/IApiResponse";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { ISystemProduct, SystemProductSchema } from "../../products/interfaces/ISystemProduct";
import { XAutoComplete } from "../../../../components/shared/forms/XAutoComplete";
import { authService } from "../../../../../data/oidc/AuthService";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from 'uuid'

type Props = {
    product: ISystemProduct
}

const EditSystemProduct = ({ product }: Props) => {

    const auth = useAuth();
    const dispatch = useDispatch();

    const [modalKey, setModalKey] = useState(Date.now());

    const productCategories = auth.categories?.productCategories ?? [];
    const categories = productCategories?.map((category: IInputCategory) => ({
        label: category.name,
        id: category.number
    }));

    useEffect(() => {
    }, [dispatch]);

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, `/products/${product.id}`)
        const data: ISystemProduct = {
            id: product.id,
            name: values.name,
            scientificName: values.scientificName,
            brand: values.brand,
            category: values.category.id,
            varieties: values.varieties,
        }

        put(url, data, (response) => {

            CloseModal("edit-system-product", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.POST_PRODUCTS,
                payload: data
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    const defaultCat = categories?.find(c => c.id == product.category) ?? { label: 'Conventional', id: 1 };

    return (
        <>
            <Formik
                initialValues={{
                    ...product,
                    category: defaultCat,
                    varieties: product.varieties || []
                }}
                validationSchema={SystemProductSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit}>

                {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
                    return <ModalWrapper
                        id="edit-system-product"
                        title="Edit System Product"
                        size="md"
                        key={modalKey}
                        submitting={isSubmitting}
                        handleSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Name"
                                    name="name"
                                />
                            </div>

                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Scientific Name"
                                    name="scientificName"
                                />
                            </div>

                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Brand"
                                    name="brand"
                                />
                            </div>

                            <div className="col-lg-6">
                                <Field
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    label="Category"
                                    options={categories}
                                    name="category"
                                    onChange={(event: any, value: {
                                        label: string,
                                        id: number
                                    }) => setFieldValue('category', value)}
                                    value={values.category}
                                />
                            </div>

                            {values.category && (values.category as any).label === 'Others' && (
                                <div className="col-lg-12">
                                    <Field
                                        type={"string"}
                                        disabled={isSubmitting}
                                        as={XTextField}
                                        label="Other Product Category"
                                        name="otherProductCategory"
                                    />
                                </div>
                            )}

                            <FieldArray name="varieties">
                                {({ push, remove }) => {
                                    return (
                                        <>
                                            {values.varieties.length === 0 && (
                                                <div className="col-lg-6">
                                                    <div className="row">
                                                        <div className="col-lg-11">
                                                            <Field
                                                                type="string"
                                                                disabled={isSubmitting}
                                                                as={XTextField}
                                                                label="Variety 1"
                                                                name={`varieties[0].name`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {values.varieties.map((variety: { name: string }, index: number) => (
                                                <div key={index} className="col-lg-6">
                                                    <div className="row">
                                                        <div className="col-lg-11">
                                                            <Field
                                                                type="string"
                                                                disabled={isSubmitting}
                                                                as={XTextField}
                                                                label={`Variety ${index + 1}`}
                                                                name={`varieties[${index}].name`}
                                                            />
                                                        </div>

                                                        {index >= 0 && (
                                                            <div className="col-lg-1 align-content-center"
                                                                style={{ cursor: "pointer", color: "grey", marginLeft: -20 }}
                                                                onClick={() => {
                                                                    remove(index);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faCircleMinus} color="#FF0000" size="lg" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                            {values.varieties.length > 0 && (
                                                <div
                                                    style={{ cursor: "pointer", color: "grey" }}
                                                    onClick={() => {
                                                        push({ id: uuidv4(), name: '' });
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faCirclePlus} color="#DF901F" size="lg" />
                                                    <span className="mx-2 align-items-center">Add another variety</span>
                                                </div>
                                            )}
                                        </>
                                    );
                                }}
                            </FieldArray>




                        </div>

                    </ModalWrapper>
                }}

            </Formik>

        </>
    );
}

export default EditSystemProduct;
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import { get } from "../../../../utils/ajax";
import { useAuth } from "../../auth";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";

interface Product {
    productId: string;
    productName: string | null;
    stock: number;
    unit: string;
}

interface CollectionCentre {
    collectionCentreId: string;
    collectionCentreName: string;
    products: Product[];
}

interface DatasetItem {
    [key: string]: any;
    collectioncentre: string;
}

const valueFormatter = (value: number | null) => {
    if (value === null) return '';

    if (value >= 1_000 || value <= -1_000) {
        return `${Number((value / 1_000).toFixed(1)).toLocaleString()} mt`;
    } else {
        return `${Number(value).toLocaleString()} kg`;
    }
};

const shortLabelFormatter = (label: string) => {
    if (label.length > 10) {
        return `${label.substring(0, 7)}...`;
    }
    return label;
};

const colors = [
    "#AA0050",
    "#5F1ED9",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#4D5360",
];

const InventorySalesProgress = () => {
    const { currentUser } = useAuth()

    const [dataset, setDataset] = useState<DatasetItem[]>([]);
    const [series, setSeries] = useState<any[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const hubId = currentUser?.hubId;
            let params = { hubId };

            const url = buildUrl(remoteRoutes.inventoryService, '/dashboard/stock/centres', params);

            get(
                url,
                (response: CollectionCentre[]) => {
                    const transformedData: DatasetItem[] = response.map((centre) => {
                        const data: DatasetItem = { collectioncentre: centre.collectionCentreName };

                        centre.products.forEach((product) => {
                            const productName = product.productName || "Unknown Product";
                            data[productName] = product.stock;
                        });

                        return data;
                    });

                    const productNames = new Set<string>();
                    response.forEach((centre) => {
                        centre.products.forEach((product) => {
                            const productName = product.productName || "Unknown Product";
                            productNames.add(productName);
                        });
                    });

                    const dynamicSeries = Array.from(productNames).map((productName: string | null, index: number) => ({
                        dataKey: productName ? productName : "Unknown Product",
                        label: productName ? productName : "Unknown Product",
                        color: colors[index % colors.length],
                        valueFormatter: (value: number) => valueFormatter(value),
                        highlightScope: {
                            highlight: "item",
                        }
                    }));
                    setDataset(transformedData);
                    setSeries(dynamicSeries);
                },
                (error) => {
                    console.error('API fetch error:', error);
                },
                () => {
                }
            );
        };

        fetchData();
    }, []);



    return (
        <Box>
            <BarChart
                dataset={dataset}
                series={series}
                layout="horizontal"
                xAxis={[{
                    disableTicks: true,
                    label: "kg",
                    valueFormatter: (value) => valueFormatter(value),
                }]}
                yAxis={[{
                    disableTicks: true,
                    scaleType: 'band',
                    dataKey: 'collectioncentre',
                    valueFormatter: (label: string) => shortLabelFormatter(label),
                }]}
                height={350}
                margin={{ right: 30, left: 75 }}
                borderRadius={8}
                grid={{ vertical: true }}
                sx={{
                    '& .MuiChartsLegend-root': { display: 'none' },
                    '& .MuiChartsGrid-root line': { stroke: '#EAEAEA', strokeDasharray: '4 4' },
                    '& .MuiChartsAxis-root .MuiChartsAxis-line': { display: 'none' },
                    '& .MuiChartsAxis-root .MuiChartsAxis-tickLabel': { fill: '#A0A0A0', fontSize: 14, fontWeight: 400 },
                }}
            />
        </Box>
    );
}

export default InventorySalesProgress;

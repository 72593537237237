import React, { useEffect, useRef, useState } from "react";
import { IButtonProps, PageHeader } from "../../components/shared/PageHeader";
import { ITableColumn, XTable } from "../../components/shared/XTable";
import { faEllipsisVertical, faFilter, faPeopleGroup, faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import { remoteRoutes } from "../../../data/constants";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../data/types";
import { FARMER_PROFILES_CONSTANTS } from "../../../data/redux/farmers/profilesReducer";
import { buildUrl } from "../../../utils/queryBuilder";
import { useAuth } from "../auth";
import toast from "react-hot-toast";
import { overrideToastDefaults } from "../../../data/toastDefaults";
import { get } from "../../../utils/ajax";
import TableWrapper from "../../components/shared/TableWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IFarmerProfile } from "./interfaces/IFarmerProfile";
import { IApiResponse, IPaginationMetadata } from "../../interfaces/IApiResponse";
import XPagination from "../../components/shared/XPagination";
import Plots from "./partials/_plots";
import { Modules } from "../../interfaces/Enums";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import FarmerProfilesFilterForm from "./modals/FarmerProfilesFilterForm";
import { IBreadcrumb } from "../../components/shared/Breadcrumbs";

type Props = {
    showHeader?: boolean
    hubId?: string
}

const FarmerProfiles = ({ showHeader = true, hubId }: Props) => {

    const [searchParams] = useSearchParams();

    const currentPage = searchParams.get('page') || 1

    const bottomRef = useRef<any>(null)
    const navigate = useNavigate()
    const [page, setPage] = useState<number>(Number(currentPage))
    const [pageSize, setPageSize] = useState<number>(10)
    const [loadMore, setLoadMore] = useState<boolean>(false)

    const [searchTerm, setSearchTerm] = useState(undefined)

    const { currentUser } = useAuth()

    const dispatch = useDispatch()
    const { data, loading }: any = useSelector((state: IState) => state.farmers)

    const [filterVisible, setFilterVisible] = useState(false);
    const [filters, setFilters] = useState<any>({});
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([])

    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 25,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0,
    });

    const [importing, setImporting] = useState(false)

    const setLoading = (status: boolean) => {
        dispatch({
            type: FARMER_PROFILES_CONSTANTS.LOADING_PROFILES,
            payload: status
        })
    }

    useEffect(() => {
        setLoading(true)
        handleFetchData(page, pagination.pageSize)
    }, [searchTerm, page, filters])

    const loadMoreProfiles = (page: number) => {
        handleFetchData(page, pagination.pageSize)
    }

    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            setSearchTerm(undefined)
        }

        if (event.keyCode === 13) {
            setSearchTerm(event.target.value)
        }
        setPage(1)
    }

    const handleLoadMore = () => {

        setLoadMore(true)

        if (pagination?.hasNextPage) {
            setPage(page + 1)
            handleFetchData(page + 1, pageSize)
            setLoadMore(false)

            if (bottomRef.current) {
                bottomRef.current.scrollIntoView({ behavior: 'smooth' })
            }

        } else {
            toast.error('No more profiles to load', overrideToastDefaults)
            setLoadMore(false)
        }
    }

    const handleFetchData = (page: number, pageSize: number) => {

        if (remoteRoutes.profiles) {
            const hub = hubId ?? currentUser?.hubId

            let params: any = { hubId: hub, page, pageSize, ...filters }

            if (searchTerm) {
                params.searchTerm = searchTerm
            }

            const url = buildUrl(remoteRoutes.profiles, '/farmers', params)
            get(url,
                (response: IApiResponse) => {

                    const { data, paginationMetadata } = response

                    setPagination(paginationMetadata)

                    dispatch({
                        type: FARMER_PROFILES_CONSTANTS.FETCH_PROFILES,
                        payload: data
                    })

                    dispatch({
                        type: FARMER_PROFILES_CONSTANTS.PAGINATION,
                        payload: paginationMetadata
                    })

                },
                () => toast.error("Error while fetching profiles", overrideToastDefaults),
                () => {
                    dispatch({ type: FARMER_PROFILES_CONSTANTS.STOP_FETCH })
                    setLoading(false)
                })
        }
    }

    const actionButtons: IButtonProps[] = [{
        label: "Profile a farmer",
        cssClass: "btn btn-primary",
        processing: importing
    }]

    const columns: ITableColumn[] = [
        { label: "Name", id: "fullname" },
        { label: "Telephone", id: "primaryTelephone" },
        { label: "District", id: "district" },
        { label: "Sub-county", id: "subCounty" },
        { label: "Parish", id: "parish" },
        { label: "Village", id: "village" },
        { label: "MAU", id: "hubName" },
    ]

    const handleRowClick = (row: IFarmerProfile) => {
        navigate(`/farmers/${row.id}`)
    }

    const [showMap, setShowMap] = useState(false)

    const farmers: IFarmerProfile[] = data;

    const plots: any[] = farmers.flatMap((farmer: IFarmerProfile) => farmer.plots);


    const handleApplyFilters = (FilterValues: any) => {
        setFilters(FilterValues);
        setPage(1);
    };

    const handleFilterClick = () => {
        setFilterVisible(!filterVisible);
    };

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Farmers', url: undefined },
            { label: 'Profiles', url: undefined }
        ])
    }, [])

    return (
        <>
            {showHeader && <PageHeader actionButtons={actionButtons} breadcrumbs={breadcrumbs}>
                <InnerMenu module={Modules.Profiles} />
            </PageHeader>}

            {
                !showMap && <div className="row">
                    <div className={filterVisible ? "col-9" : "col-12"}>
                        <TableWrapper>
                            <div className="d-flex w-100 align-items-center justify-content-between">
                                <div className="input-group w-25">
                                    <input type="text" onKeyUp={(event) => handleSearch(event)}
                                        className="form-control"
                                        placeholder="Type to search..." />
                                    <span className="input-group-text" id="addon-wrapping">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </span>
                                </div>

                                <div className="action-buttons">
                                    <button
                                        onClick={handleFilterClick}
                                        type="button"
                                        className="btn btn-sm btn-outline-secondary ms-2">
                                        <FontAwesomeIcon icon={faFilter} className="text-orange" />
                                        <span className="ms-2">Filters</span>
                                    </button>
                                    {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2">*/}
                                    {/*    <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>*/}
                                    {/*</button>*/}
                                </div>

                            </div>
                            <XTable checked={false} onRowClick={(row) => handleRowClick(row)} loadingMore={loadMore}
                                loading={loading} columns={columns} data={data} />

                            <XPagination
                                dataLength={data.length}
                                pagination={pagination}
                                currentPage={page}
                                setPage={(page) => setPage(page)} />

                        </TableWrapper>
                        {/*<EnhancedTable columns={columns} data={data} loading={loading}/>*/}
                    </div>
                    {filterVisible && (
                        <div className="col-3">
                            <TableWrapper>
                                <div
                                    className="border-bottom mb-2 pt-3 pb-2 d-flex w-100 justify-content-between align-content-center">
                                    <div style={{ fontSize: 15, fontWeight: 400 }}>Filter</div>
                                    <div style={{ cursor: "pointer" }}>
                                        <FontAwesomeIcon icon={faXmark} onClick={handleFilterClick} size="lg" />
                                    </div>
                                </div>
                                <FarmerProfilesFilterForm onApplyFilters={handleApplyFilters} setFilters={setFilters} />
                            </TableWrapper>
                        </div>
                    )}
                </div>
            }

            {
                showMap && <div className="row">
                    <div className="col-12">
                        <Plots plots={plots} />
                    </div>
                </div>
            }

        </>
    )
}

export { FarmerProfiles }

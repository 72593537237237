import TableWrapper from "../../components/shared/TableWrapper";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";
import { PageHeader } from "../../components/shared/PageHeader";
import React, { useEffect, useState } from "react";
import { ITableColumn, XTable } from "../../components/shared/XTable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth";
import { ICollectionCentre } from "../settings/interfaces/IHub";
import { IApiResponse, IPaginationMetadata } from "../../interfaces/IApiResponse";
import { SETTINGS_CONSTANTS } from "../../../data/redux/settings/settingsReducer";
import { IState } from "../../../data/types";
import { remoteRoutes } from "../../../data/constants";
import { buildUrl } from "../../../utils/queryBuilder";
import { get } from "../../../utils/ajax";
import { toast } from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../data/toastDefaults";
import { IBatchSale } from "./interfaces/IBatchSale";
import XPagination from "../../components/shared/XPagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import { IBatchSalesState, INVENTORY_SALES_CONSTANTS } from "../../../data/redux/inventory/batchSalesReducer";

const columns: ITableColumn[] = [
    {
        label: 'Date',
        id: 'date',
        link: undefined,
        isNumberFormat: false,
        isDate: true,
        textAlign: 'text-start'
    },
    {
        label: 'Batch #',
        id: 'batchId',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Buyer',
        id: 'buyerId',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Product',
        id: 'product',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Quantity',
        id: 'quantity',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-end'
    },
    {
        label: '',
        id: 'unitName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    }
]

const BatchSales = () => {

    document.title = "Inventory > Sales"

    const dispatch = useDispatch();

    const navigate = useNavigate()

    const { currentUser } = useAuth();
    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [selected, setSelected] = useState<any[]>([])
    const [filteredSales, setFilteredSales] = useState<IBatchSale[]>([]);

    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 8,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });

    const { data, loading }: IBatchSalesState = useSelector((state: IState) => state.batchSales);

    const [importing, setImporting] = useState<boolean>(false);
    const [showActionButtons, setShowActionButtons] = useState<boolean>(true)
    const [selectedSales, setSelectedSale] = useState<IBatchSale>()

    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            setSearchTerm(undefined)
        }

        if (event.keyCode === 13) {
            setSearchTerm(event.target.value)
        }
    }

    const setLoading = (state: boolean) => {
        dispatch({
            type: SETTINGS_CONSTANTS.LOADING_DATA,
            payload: state
        })
    }

    const filterBatchSales = () => {
        if (searchTerm) {
            const sales: IBatchSale[] = data;
            setFilteredSales(sales.filter(sale =>
                sale.collectionCentreName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                sale.unitName?.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        } else {
            setFilteredSales(data);
        }
    }

    useEffect(() => {
        filterBatchSales()
    }, [searchTerm, data]);

    useEffect(() => {
        setLoading(true);
        handleFetchData(page, pagination.pageSize);
    }, [page]);

    const handleFetchData = (page: number, pageSize: number) => {
        if (remoteRoutes.inventoryService) {
            const hubId = currentUser?.hubId;
            let params: any = { hubId, page, pageSize };

            if (searchTerm) {
                params.searchTerm = searchTerm;
            }

            const url = buildUrl(remoteRoutes.inventoryService, '/exports', params);
            get(url,
                (response: IApiResponse) => {
                    const { data, paginationMetadata } = response;
                    setPagination(paginationMetadata);

                    dispatch({
                        type: INVENTORY_SALES_CONSTANTS.FETCH_DATA,
                        payload: data
                    });

                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
                    setLoading(false);
                });
        }
    }

    const handleSelectAll = (rows: any[]) => {
        setSelected(prevSelected => {
            if (prevSelected.length === rows.length) {
                return [];
            } else {
                return rows.map(row => row.id);
            }
        })
    };

    const handleCheckRow = (item: ICollectionCentre) => {

        setSelected(prevSelected => {
            const newSelected = prevSelected ? [...prevSelected] : []

            const index = newSelected.indexOf(item.id);
            if (index > -1) {
                // Remove the id if it's already in the array
                newSelected.splice(index, 1);
            } else {
                // Add the id if it's not in the array
                newSelected.push(item.id);
            }

            return newSelected
        })
    };

    const handleClickRow = (item: IBatchSale) => {
        setDrawerVisible(!drawerVisible);
        setSelectedSale(item)
    };

    return (
        <>
            <PageHeader title={"Inventory > Sales"}>
                <InnerMenu module={Modules.Inventory} />
            </PageHeader>

            <TableWrapper>

                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div className="input-group w-auto">
                        <input type="text" onKeyUp={(event) => handleSearch(event)}
                            className="form-control"
                            placeholder="Type to search..." />
                        <span className="input-group-text" id="addon-wrapping">
                            <FontAwesomeIcon icon={faSearch} />
                        </span>
                    </div>

                    <div className="action-buttons">

                        <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                            <FontAwesomeIcon icon={faFilter} className="text-orange" />
                            <span className="ms-2">Filters</span>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-secondary ms-2 me-2">
                            <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange" />
                        </button>


                    </div>
                </div>

                <XTable
                    selected={selected}
                    onSelectAll={(rows) => handleSelectAll(rows)}
                    onRowClick={(row) => handleClickRow(row)}
                    onCheckRow={(row) => handleCheckRow(row)}
                    data={filteredSales}
                    columns={columns}
                    loading={loading} />

                <XPagination
                    dataLength={data.length}
                    pagination={pagination}
                    currentPage={page}
                    setPage={(page) => setPage(page)} />
            </TableWrapper>
        </>
    );
}

export default BatchSales;
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { Field, Form, Formik } from "formik";
import { post } from "../../../../utils/ajax";
import { buildUrl } from "../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../data/constants";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../data/toastDefaults";
import moment from "moment";
import { useAuth } from "../../auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { XTextArea } from "../../../components/shared/forms/XTextArea";
import { XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import { ICollectionCentre, IHub } from "../../settings/interfaces/IHub";
import { authService } from "../../../../data/oidc/AuthService";
import { MoveBatchSchema } from "../schemas/MoveBatchSchema";
import { IBatch } from "../interfaces/IBatch";
import { XRadioGroup } from "../../../components/shared/forms/XRadioGroup";
import { INVENTORY_BATCH_MOVEMENTS_CONSTANTS } from "../../../../data/redux/inventory/batchMovementsReducer";
import { INVENTORY_BATCHES_CONSTANTS } from "../../../../data/redux/inventory/batchesReducer";
import { BatchStatus } from "../interfaces/BatchStatus";

type Props = {
    batch?: IBatch | null
}

export const movements = [
    { id: 1, label: "Centre to Centre" },
    { id: 2, label: "Agent to Agent", disabled: true },
    { id: 3, label: "Farmer to Agent", disabled: true },
]

export const MoveBatchForm = ({ batch }: Props) => {

    const dispatch = useDispatch()
    const { currentUser } = useAuth()
    const [locations, setLocations] = useState<any[] | undefined>(undefined)

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        date: batch?.date ? new Date(batch.date as string).toISOString() : new Date().toISOString(),
        destinationCollectionCentre: null,
        typeOfMovement: 1,
        remarks: ''
    }

    useEffect(() => {

        const hub: IHub | undefined = authService.getHub()

        if (hub) {
            const { collectionCentres } = hub
            setLocations(collectionCentres?.map((center: ICollectionCentre) => ({ label: center.name, id: center.id })))
        }
    }, [])

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.inventoryService, '/movements')
        const data = {
            hubId: currentUser?.hubId,
            date: moment().toISOString(),
            batchId: batch?.id,
            batchNumber: batch?.batchNumber,
            sourceCollectionCentreId: batch?.currentCollectionCentreId,
            sourceCollectionCentreName: batch?.currentCollectionCentreName,
            destinationCollectionCentreId: values.destinationCollectionCentre.id,
            destinationCollectionCentreName: values.destinationCollectionCentre.label,
            typeOfMovement: values.typeOfMovement,
            remarks: values.remarks
        }

        post(url, data, (response) => {

            console.log("Submitted Data", data)
            CloseModal("move-batch", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: INVENTORY_BATCH_MOVEMENTS_CONSTANTS.POST_BATCH_MOVEMENT,
                payload: data
            })

            dispatch({
                type: INVENTORY_BATCHES_CONSTANTS.UPDATE_BATCH,
                payload: { ...batch, currentStatus: BatchStatus.InTransit }
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (

        <Formik
            initialValues={initialValues}
            validationSchema={MoveBatchSchema}
            onSubmit={handleSubmit}>
            {({ handleSubmit, isSubmitting, errors, values, setFieldValue }) => {

                return <ModalWrapper
                    id="move-batch"
                    title={"Move a Batch"}
                    size="lg"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <Form>
                        <div className="row">
                            <div className="col-12 col-lg-12">
                                <Field
                                    row={true}
                                    disabled={isSubmitting}
                                    component={XRadioGroup}
                                    errors={errors}
                                    value={values.typeOfMovement}
                                    options={movements || []}
                                    label="Type of Movement"
                                    name="typeOfMovement"
                                />
                            </div>

                            <div className="col-lg-12 col-12">
                                <Field
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={locations || []}
                                    value={values.destinationCollectionCentre}
                                    getOptionLabel={(option: any) => (option ? option.label : '')}
                                    label="Destination"
                                    name="destinationCollectionCentre"
                                />
                            </div>

                            <div className="col-lg-12">
                                <Field
                                    disabled={isSubmitting}
                                    component={XTextArea}
                                    rows={6}
                                    value={values.remarks}
                                    label="Remarks"
                                    name="remarks"
                                />
                            </div>

                        </div>
                    </Form>
                </ModalWrapper>

            }}
        </Formik>


    )
}


import React, { useCallback, useEffect, useState } from "react";
import { Box } from '@mui/material';
import { BarChart } from "@mui/x-charts";
import { getAll } from "../../../../utils/ajax";
import { useAuth } from "../../auth";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { Loading } from "../../../components/shared/Loading";

interface MonthlyVolume {
    month: string;
    volume: number;
}

interface DatasetItem {
    [key: string]: any;
    month: string;
    inflowVolume: number;
    outflowVolume: number;
}

const CustomLegend = () => {
    const graphLegendData = [
        {
            label: "Purchases",
            color: "#DF901F",
        },
        {
            label: "Sales",
            color: "#1C5838",
        }
    ];

    return (
        <Box display="flex" justifyContent="center" alignItems="center" gap={1} paddingBottom={2}>
            {graphLegendData.map((item, index) => (
                <Box key={index} display="flex" alignItems="center">
                    <Box className="custom-graph-legend"
                        sx={{ backgroundColor: item.color, width: 16, height: 16, borderRadius: '50%' }}
                    />
                    <span style={{ color: '#848484', marginLeft: 4 }}>
                        {item.label}
                    </span>
                </Box>
            ))}
        </Box>
    );
};

const valueFormatter = (value: number | null) => {
    if (value === null) return '';

    if (value >= 1_000) {
        return `${(value / 1_000).toFixed(1)} mt`;
    } else {
        return `${value} kg`;
    }
};

export const InventoryVersusSalesGraph: React.FC<{ productId: string | null }> = ({ productId }) => {
    const { currentUser } = useAuth();

    const [dataset, setDataset] = useState<DatasetItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [noData, setNoData] = useState<boolean>(false);

    useEffect(() => {
        if (productId) {
            setIsLoading(true);
            setError(null);
            setNoData(false);
            fetchData(productId)
                .finally(() => setIsLoading(false));
        }
    }, [productId]);

    const fetchData = useCallback(async (productId: string | null) => {
        if (!productId) return;

        const hubId = currentUser?.hubId;
        const inflowUrl = buildUrl(remoteRoutes.inventoryService, '/dashboard/inflow/volumes', { hubId, productId });
        const outflowUrl = buildUrl(remoteRoutes.inventoryService, '/dashboard/outflow/volumes', { hubId, productId });

        try {
            const [inflowResponse, outflowResponse] = await Promise.all([
                getAll(inflowUrl),
                getAll(outflowUrl),
            ]);

            if (inflowResponse.monthlyVolumes.length === 0 && outflowResponse.monthlyVolumes.length === 0) {
                setNoData(true);
                return;
            }

            const inflowData: DatasetItem[] = inflowResponse.monthlyVolumes.map((item: MonthlyVolume) => ({
                month: item.month,
                inflowVolume: item.volume,
                outflowVolume: 0, // Default value, will be updated later
            }));

            const outflowData: DatasetItem[] = outflowResponse.monthlyVolumes.map((item: MonthlyVolume) => ({
                month: item.month,
                outflowVolume: item.volume,
            }));

            const mergedData = inflowData.map(inflowItem => {
                const outflowItem = outflowData.find(outflow => outflow.month === inflowItem.month);
                return {
                    ...inflowItem,
                    outflowVolume: outflowItem ? outflowItem.outflowVolume : 0,
                };
            });

            setDataset(mergedData);
            setNoData(false);
        } catch (error) {
            console.error('API fetch error:', error);
            setError('No data available for the selected product.');
            setNoData(false);
        }
    }, [currentUser?.hubId]);

    return (
        <>
            {isLoading ? <Loading /> :
                error ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        sx={{ fontSize: '16px', color: 'red', textAlign: 'center' }}
                    >
                        {error}
                    </Box>
                ) :
                    noData ? (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            sx={{ fontSize: '16px', color: '#555', textAlign: 'center' }}
                        >
                            No data available for the selected product.
                        </Box>
                    ) :
                        <Box>
                            <BarChart
                                dataset={dataset}
                                borderRadius={6}
                                xAxis={[{ scaleType: 'band', dataKey: 'month', disableTicks: true }]}
                                series={[
                                    {
                                        dataKey: 'inflowVolume',
                                        label: 'Purchases',
                                        color: '#DF901F',
                                        valueFormatter: valueFormatter,
                                        highlightScope: { highlight: "item" },
                                    },
                                    {
                                        dataKey: 'outflowVolume',
                                        label: 'Sales',
                                        color: '#1C5838',
                                        valueFormatter: valueFormatter,
                                        highlightScope: { highlight: "item" },
                                    },
                                ]}
                                yAxis={[{
                                    disableTicks: true,
                                    valueFormatter: valueFormatter,
                                }]}
                                height={350}
                                margin={{
                                    right: 20,
                                    left: 70
                                }}
                                grid={{ horizontal: true }}
                                sx={{
                                    '& .MuiChartsLegend-root': {
                                        display: 'none',
                                    },
                                    '& .MuiChartsGrid-root line': {
                                        stroke: '#EAEAEA',
                                        strokeDasharray: '4 4',
                                    },
                                    '& .MuiChartsAxis-root .MuiChartsAxis-line': {
                                        display: 'none',
                                    },
                                    '& .MuiChartsAxis-root .MuiChartsAxis-tickLabel': {
                                        fill: '#A0A0A0',
                                        fontsize: 14,
                                        fontWeight: 400,
                                    },
                                }}
                            />
                            <CustomLegend />
                        </Box>
            }
        </>
    );
};

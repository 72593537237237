import React, { useEffect, useState } from "react";
import {
  faEllipsisVertical,
  faFilter,
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { IButtonProps, PageHeader } from "../../components/shared/PageHeader";
import { PageHeaderLoader } from "../../components/shared/loaders/PageHeaderLoader";
import TableWrapper from "../../components/shared/TableWrapper";
import XTable, { ITableColumn } from "../../components/shared/XTable";
import XPagination from "../../components/shared/XPagination";
import { IApiResponse, IPaginationMetadata } from "../../interfaces/IApiResponse";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoveInputsForm from "./modals/MoveInputsForm";
import InputMovementDetails from "./InputMovementDetails";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import { useDispatch, useSelector } from "react-redux";
import { IInputsDistributionState, INPUTS_DISTRIBUTION_CONSTANTS } from "../../../data/redux/inputs/inputsReducer";
import { IState } from "../../../data/types";
import { get } from "../../../utils/ajax";
import toast from "react-hot-toast";
import { remoteRoutes } from "../../../data/constants";
import { toastMessages, overrideToastDefaults } from "../../../data/toastDefaults";
import { buildUrl } from "../../../utils/queryBuilder";
import { useAuth } from "../auth";
import { IBreadcrumb } from "../../components/shared/Breadcrumbs";
import { authService } from "../../../data/oidc/AuthService";
import { IHub } from "../settings/interfaces/IHub";
import InputMovementsFilterForm from "./modals/InputMovementsFilterForm";

const columns: ITableColumn[] = [
  {
    label: "Moving Date",
    isDate: false,
    id: "date",
    textAlign: "text-start",
  },
  {
    label: "Movement Number",
    id: "movementNumber",
    textAlign: "text-start",
  },
  {
    label: "Input Name",
    id: "inputName",
    textAlign: "text-start",
  },
  {
    label: "Quantity",
    isNumberFormat: true,
    id: "quantity",
    textAlign: "text-end",
  },
  {
    label: "",
    id: "unitName",
    textAlign: "text-start",
  },
  {
    label: "Source",
    id: "sourceCollectionCentreName",
    textAlign: "text-start",
  },
  {
    label: "Destination",
    id: "destinationCollectionCentreName",
    textAlign: "text-start",
  },
  {
    label: "Status",
    id: "movementStatus",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
];


export const InputMovements = () => {
  document.title = "Inputs > Movements";
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filters, setFilters] = useState<any>({});

    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 15,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });

    const [showActionButtons, setShowActionButtons] = useState<boolean>(true)
    const { data, loading }: IInputsDistributionState = useSelector((state: IState) => state.inputs);

    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            setSearchTerm(undefined);
        }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
    setPage(1);
  };

  const handleApplyFilters = (FilterValues: any) => {
    setFilters(FilterValues);
    setPage(1);
  };

  const handleRowClick = (item: any) => {
    setDrawerVisible(true);
    setSelectedRow(item);
  };

  const handleFilterClick = () => {
    setFilterVisible(!filterVisible);
  };

    const setLoading = (state: boolean) => {
        dispatch({
            type: INPUTS_DISTRIBUTION_CONSTANTS.LOADING_DATA,
            payload: state
        });
    };

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination.pageSize);
  }, [page, searchTerm, filters]);

    const handleFetchData = (page: number, pageSize: number) => {
        const hub: IHub | undefined = authService.getHub()

        if (remoteRoutes.profiles) {
            const hubId = currentUser?.hubId;

            const parentHubId = hub?.parentHubId

            const CollectionCentreIds = hub?.collectionCentres?.map(centre => centre.id);

            let params: any;

            if (parentHubId === null) {
                params = { hubId, page, pageSize, ...filters };
            } else {
                params = { hubId, parentHubId, CollectionCentreIds, page, pageSize, ...filters };
            }

      if (searchTerm) {
        params.searchTerm = searchTerm;
      }

            const url = buildUrl(remoteRoutes.inputsService, '/input/movements', params);
            get(url,
                (response: IApiResponse) => {
                    const { data, paginationMetadata } = response;
                    setPagination(paginationMetadata);

                    dispatch({
                        type: INPUTS_DISTRIBUTION_CONSTANTS.FETCH_DATA,
                        payload: data
                    });

                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({ type: INPUTS_DISTRIBUTION_CONSTANTS.STOP_FETCH });
                    setLoading(false);
                });
        }
    };

    const formattedDate = data.map((row: any) => ({
        ...row,
        date: printNaturalDateShort(row.date)
    }))

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inputs', url: undefined },
            { label: 'Movements', url: undefined }
        ])
    }, [])

  return (
    <>
      {loading && <PageHeaderLoader />}
      {!loading && (
        <>
          <PageHeader breadcrumbs={breadcrumbs}>
            <InnerMenu module={Modules.Inputs} />
          </PageHeader>
        </>
      )}

      <div className="row">
        <div className={filterVisible ? "col-9" : "col-12"}>
          <TableWrapper>
            <div className="d-flex w-100 align-items-center justify-content-between">
              <div className="input-group w-25">
                <input
                  type="text"
                  onKeyUp={(event) => handleSearch(event)}
                  className="form-control"
                  placeholder="Type to search..."
                />
                <span className="input-group-text" id="addon-wrapping">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
              <div className={"d-flex"}>
                <div className="action-buttons">
                  <button
                    onClick={handleFilterClick}
                    type="button"
                    className="btn btn-sm btn-outline-secondary ms-2"
                  >
                    <FontAwesomeIcon icon={faFilter} className="text-orange" />
                    <span className={"ms-2"}>Filters</span>
                  </button>
                </div>
              </div>
            </div>
            <XTable
              selected={[]}
              checked={false}
              onRowClick={(item) => handleRowClick(item)}
              data={formattedDate}
              columns={columns}
              loading={loading}
            />
            <XPagination
              currentPage={page}
              pagination={pagination}
              dataLength={data.length}
              setPage={setPage}
            />
          </TableWrapper>
        </div>

        {filterVisible && (
          <div className="col-3">
            <TableWrapper>
              <div className="border-bottom mb-2 pt-3 pb-2 d-flex w-100 justify-content-between align-content-center">
                <div style={{ fontSize: 15, fontWeight: 400 }}>Filter</div>
                <div style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={handleFilterClick}
                    size="lg"
                  />
                </div>
              </div>
              <InputMovementsFilterForm
                onApplyFilters={handleApplyFilters}
                setFilters={setFilters}
              />
            </TableWrapper>
          </div>
        )}
      </div>

      <InputMovementDetails
        close={() => setDrawerVisible(false)}
        show={drawerVisible}
        selectedRow={selectedRow}
      />
    </>
  );
};

import { XSidebarWrapper } from "../../components/shared/XSidebarWrapper"
import React, { useEffect, useState } from "react"
import { IBatch } from "./interfaces/IBatch"
import { remoteRoutes } from "../../../data/constants"
import { put } from "../../../utils/ajax"
import toast from "react-hot-toast"
import { overrideToastDefaults, toastMessages } from "../../../data/toastDefaults"
import { useSelector } from "react-redux"
import { useAuth } from "../auth"
import useFetchPurchases from "../../hooks/useFetchPurchases"
import { IState } from "../../../data/types"
import XTable from "../../components/shared/XTable"
import { PurchaseColumns } from "./Purchases"
import XPagination from "../../components/shared/XPagination"
import { XSearchFilter } from "../../components/shared/XSearchFilter"
import { printBatchStatus } from "../../../utils/enumHelpers"
import { IHeaderButtonProps } from "../../components/shared/SettingsPageHeader"
import SellBatchForm from "./modals/SellBatchForm"
import { printNaturalDateShort } from "../../../utils/dateHelpers"

type Props = {
    close: () => void,
    show: boolean,
    batch: IBatch | null
}

export function BatchDetails({ close, show, batch }: Props) {

    const { currentUser } = useAuth()
    const { fetchPurchases, pagination } = useFetchPurchases()
    const { data, loading }: any = useSelector((state: IState) => state.purchases)

    const [updatingStatus, setUpdatingStatus] = useState<boolean>(false)

    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)

    useEffect(() => {
        let params: any = { hubId: currentUser?.hubId, id: batch?.id }
        if (searchTerm) params = { ...params, searchTerm }

        fetchPurchases(page, 7, params)
    }, [batch, page, searchTerm])

    const handleSearchPurchases = (event: any) => {

        const { value } = event.target
        if (!value) setSearchTerm(undefined)

        else {
            if (value && event.keyCode === 13) {
                setSearchTerm(value)
            }
        }
    }

    const handleUpdateStatus = (status: any) => {
        setUpdatingStatus(true)
        const updatedBatch = { ...batch, currentStatus: status }

        const url = remoteRoutes.inventoryService + '/batches/' + batch?.id
        put(url, updatedBatch, (response) => {
            toast.success(toastMessages.default.success, overrideToastDefaults)
            setUpdatingStatus(false)
        }, error => {
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    const actionButton: IHeaderButtonProps = {
        label: "Export",
        cssClass: "btn btn-primary btn-sm",
        dataTarget: "#sell-batch",
        dataToggle: "modal"
    };

    const status = printBatchStatus(batch?.currentStatus);

    const formattedData = data.map((row: { date: string }) => ({
        ...row,
        date: printNaturalDateShort(row.date)
    }))

    return (
        <>
            <XSidebarWrapper title={"Batch Details"} width={50} close={close} show={show}>
                <div className="d-flex justify-content-between w-100 align-items-baseline border-bottom mb-2">
                    <div className="py-2">
                        <h5>{batch?.batchNumber.toUpperCase()}</h5>
                        <div
                            className="small">{Number(batch?.quantity).toLocaleString()} {batch?.unitName}
                        </div>
                        <div className="fs-6 small">
                            <span className="badge text-bg-info">
                                {batch?.productName}
                            </span>
                        </div>
                    </div>
                    <div className={"w-75 text-end"}>

                        {batch?.currentStatus !== 6 ?
                            <div className="btn-group" role="group">
                                <button id="btnGroupDrop1" type="button"
                                    className={`btn btn-outline-dark dropdown-toggle ${updatingStatus ? "disabled" : ""}`}
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {updatingStatus ? "Processing..." : "Manage"}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                    <li><a className="dropdown-item" data-bs-toggle={"modal"}
                                        data-bs-target={"#move-batch"}
                                        href="#">Move Batch</a></li>
                                    <li className="dropdown-divider"></li>
                                    <li><a onClick={() => handleUpdateStatus(3)} className="dropdown-item" href="#">Confirm
                                        Delivery</a></li>
                                    <li><a onClick={() => handleUpdateStatus(4)} className="dropdown-item" href="#">Send
                                        for
                                        Processing</a></li>
                                    <li className="dropdown-divider"></li>
                                    {/*<li><a onClick={() => handleUpdateStatus(6)} className="dropdown-item" href="#">Mark as Sold</a></li>*/}
                                    <li>
                                        <button
                                            data-bs-toggle={actionButton.dataToggle}
                                            data-bs-target={actionButton.dataTarget}
                                            className="dropdown-item"
                                            key={1}
                                            onClick={actionButton.onClick}>
                                            {actionButton.processing ? 'Please wait...' : actionButton.label}
                                        </button>
                                    </li>
                                </ul>
                            </div> :
                            <span>{status}</span>
                        }

                    </div>
                </div>
                <div className="scrollable-sidebar">
                    <div className="p-3 bg-gray rounded-3 mb-2">
                        <div className="row">
                            <div className="col-lg-6 col-12 mb-4">
                                <strong className="small fw-semibold">Batched from</strong><br />
                                <span>{batch?.batchingCollectionCentreName}</span>
                            </div>
                            <div className="col-lg-6 col-12 mb-4">
                                <strong className="small fw-semibold">Current Location</strong><br />
                                <span>{batch?.currentCollectionCentreName}</span>
                            </div>
                            <div className="col-lg-6 col-12 mb-2">
                                <strong className="small fw-semibold">Status</strong><br />
                                <span>{status}</span>
                            </div>
                            <div className="col-lg-6 col-12 mb-2">
                                <strong className="small fw-semibold">Quantity</strong><br />
                                <span>{Number(batch?.quantity).toLocaleString()} {batch?.unitName}</span>
                            </div>
                        </div>
                    </div>
                    {batch?.description && <div className="p-3 bg-gray rounded-3 mb-2">
                        <div className="row">
                            <div className="col-lg-12 col-12 mb-2">
                                <strong className="small fw-semibold">Description</strong><br />
                                <span>{batch?.description}</span>
                            </div>
                        </div>
                    </div>}

                    <div className="rounded-3 mt-3">

                        <h5>Purchases</h5>
                        <XSearchFilter onKeyUp={(event) => handleSearchPurchases(event)} />
                        <XTable checked={false} data={formattedData} columns={PurchaseColumns} loading={loading} />
                        <XPagination
                            dataLength={data.length}
                            pagination={pagination}
                            currentPage={page}
                            setPage={(page) => setPage(page)} />
                    </div>
                </div>

                {/*<MoveBatchForm initialValues={{}} quantity={120} batchIds={[]} />*/}

            </XSidebarWrapper>;
            <SellBatchForm initialValues={batch} />
        </>
    )


}